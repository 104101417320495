import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

class About extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title='About Black Alsatian Website Design Company'
          description='About our little web design company on the Southern tip of Africa.'
          keywords={[
            'web design',
            'web development',
            'online marketing',
            'digital marketing',
          ]}
        />

        <div id='main' className='alt'>
          <div className='inner'>
            <header className='major'>
              <h1>Building A Web Design Company</h1>
            </header>
            <span className='image main'>
              <Img
                fluid={data.shanukPic.childImageSharp.fluid}
                alt='Web development code on computer screen'
              />
            </span>
            <p>
              Black Alsatian was born in 2003 when Bj&ouml;rn branched off from
              graphic design &amp; signwriting to do his own thing. He had a PC,
              a contact book, and a fascination with building awesome things
              with code.
            </p>
            <p>
              Some years later, Louisa joined him straight out of big-business
              advertising. She had a library of Carl Sagan and noise rock, and a
              fascination with the code inherent in human nature.
            </p>
            <p>
              With the help of star stuff and gigabytes, they lived happily ever
              after.
            </p>
            <div className='grid-wrapper'>
              <div className='col-8'>
                <header className='major'>
                  <h3>Web Design Is Who We Are.</h3>
                </header>
                <p>
                  By combining our skills, we have created a small but highly
                  effective web design and online marketing agency that stands
                  its ground against some of the giants in the business. We have
                  worked with clients from around the globe, and collaborated
                  with international agencies at the top of the internet game.
                </p>
                <p>
                  With every project we take on, we use the most cutting-edge
                  web design tech available. We are Full Stack Developers,
                  fluent in PHP, MySQL, Apache and frameworks like ReactJS and
                  Laravel. And yes, we also do the odd WordPress and Joomla
                  thing.
                </p>
                <p>
                  Mom &amp; Pop Shop? Maybe a little, because we're a creative
                  family. Our "kids" are all experts in their fields. We work
                  with top tech engineers and artists in web dev, graphic
                  design, digital marketing and content writing.
                </p>
                <p>
                  We are a group of creative individuals and strategic thinkers
                  who push boundaries to the n<sup>th</sup> degree. We don't
                  take ourselves too seriously, but you can bet we take YOUR
                  needs seriously.
                </p>
              </div>
              <div className='col-4'>
                <span className='image fit padded'>
                  <Img
                    fluid={data.aboutPic.childImageSharp.fluid}
                    alt='Black Alsatian Mascot'
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query {
    aboutPic: file(relativePath: { eq: "black-als-pup-sml.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shanukPic: file(relativePath: { eq: "about-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
